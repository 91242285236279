import React, { FC, KeyboardEventHandler, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Badge,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FieldType, productActions } from '../../data-management/productsSlice';
import { Check, Edit } from '@material-ui/icons';
import { createStyles } from '@material-ui/styles';
import { NoteModal } from './NoteModal';
import { UniqueEdgesSelectorModal } from './UniqueEdgesSelectorModal';
import { RalNumberInputModal } from './RalNumberInputModal';
import { useCategoryId } from '../../hooks/useCategoryId';
import { FieldOption } from '../../data-management/fieldOptionsSlice';
import { useFieldOptionsCache } from '../../hooks/useFieldOptionsCache';
import { AutocompleteChangeReason, Value } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import { ExtrasPanel } from './ExtrasPanel';
import { useProduct } from '../../hooks/useProduct';
import { ProductImageModal } from './ProductImageModal';
import { ProductItemRemoveModal } from './ProductItemRemoveModal';
import { ProductValidator } from './ProductValidator';
import { useAddProduct } from '../../hooks/useAddProduct';
import { ProductSizeRuleManager } from './ProductSizeRuleManager';
import { ProductSizeFields } from './ProductSizeFields';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#535353 !important',
    opacity: 0.5,
    '&:hover': {
      cursor: 'pointer',
      opacity: 1,
    },
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(0.5),
  },
  autocompleteInputRoot: {
    paddingTop: theme.spacing(0.25),
  },
  itemInfo: {
    '&:hover': {
      '& div.image-wrapper': {
        display: 'block',
      },
    },
  },
  buttonRootNoBottomMargin: {
    marginBottom: 0,
  },
}));

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(196, 196, 196, 0.1)',
      },
    },
  }),
)(TableRow);

const StyledTableCell = withStyles((theme) =>
  createStyles({
    root: {
      border: 'none',
      maxWidth: '10vw',
      verticalAlign: 'baseline',
    },
  }),
)(TableCell);

type ProductItemProps = {
  productId: number
}

export const ProductItem: FC<ProductItemProps> = React.memo((
  {
    productId,
  },
) => {
  const {
    fields,
    catId,
  } = useCategoryId();

  const classes = useStyles();
  const product = useProduct(productId);
  const onAddProduct = useAddProduct();

  const { getFieldOptions } = useFieldOptionsCache();

  const [removeDialog, setRemoveDialog] = useState(false);
  const [noteDialog, setNoteDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [extrasModal, setExtrasModal] = useState(false);

  const [image, setImage] = useState<string | undefined>(undefined);

  const [enabledFields, setEnabledFields] = useState<string[]>([]);
  const dispatch = useDispatch();

  const [fieldOptions, setFieldOptions] = useState<{
    [name: string]: FieldOption[]
  }>({});
  const halfValid = product.validationState === 'correct' ||
    product.validationState === 'waiting' ||
    product.validationState === 'processing';

  const [szinError, setSzinError] = useState<string | undefined>(undefined);
  const [quantError, setQuantError] = useState<string | undefined>(undefined);
  const [productError, setProductError] = useState<string | undefined>(undefined);

  const [akril4DifferentEdgeModal, setAkril4DifferentEdgeModal] = useState(false);
  const [ralModal, setRalModal] = useState(false);
  const [akril4DifferentEdgeEdit, setAkril4DifferentEdgeEdit] = useState(false);
  const [ralEdit, setRalEdit] = useState(false);

  useEffect(() => {
    if (fields && fields.length > 0)
      setEnabledFields([fields[0].code]);
  }, [fields]);

  useEffect(() => {
    const filledFeatures = product.features.map(({ code }) => code);
    if (product.product_id) filledFeatures.push('PROD');
    if (product.quantity) filledFeatures.push('QUANT');

    const newEnabledFields = fields.length > 0 ? [fields[0].code] : [];

    let i = 0;
    fields.some(({ code }) => {
      if (filledFeatures.includes(code)) {
        newEnabledFields.push(code);
        i++;
        return false;
      }
      return true;
    });

    if (fields[i]) {
      newEnabledFields.push(fields[i].code);
    }

    if (JSON.stringify(newEnabledFields) !== JSON.stringify(enabledFields))
      setEnabledFields(newEnabledFields);

  }, [product, fields]);

  useEffect(() => {
    fields.forEach((field) => {
      getFieldOptions(productId, field.code).then(
        (options: any) => {
          setFieldOptions(fieldOptions => ({
            ...fieldOptions,
            [field.code]: options,
          }));
        },
      );
    });
    const szinFeature = product.features.find(({ code }) => code === 'SZIN');
    if (szinFeature && szinFeature.option_code === 'RAL_NCS' && szinFeature.keyed_value !== undefined) {
      setRalEdit(true);
    }
    const elz1Feature = product.features.find(({ code }) => code === 'ELZ1');
    if (elz1Feature) {
      setAkril4DifferentEdgeEdit(true);
    }

  }, [product, productId, fields]);

  useEffect(() => {
    const prod_item_field = (valueMapper("PROD") as FieldOption);
    //
    if(product.validationState == 'correct' && typeof prod_item_field !== 'undefined' && prod_item_field !== null && typeof prod_item_field.minta_tip !== 'undefined' && prod_item_field.minta_tip.length > 0) {
      setQuantError(prod_item_field.minta_tip[0].description);
    }else{
      setQuantError("");
    }

  }, [product, productId, fields]);

  const startValidation = (field_code: string, item: FieldOption | undefined ) => {
    if(field_code === 'PROD'){
      if (typeof item !== 'undefined' && item !== null && item.status === 'HIANYCIKK')
        setProductError('Figyelem az adott termék hiánycikk!');
      else if (typeof item !== 'undefined' && item !== null && item.status === 'KIFUTO')
        setProductError('Figyelem az adott termék kifutó!');
      else if (typeof item !== 'undefined' && item !== null && item.status === 'MEGSZUNT')
        setProductError('Figyelem a kiválasztott termék megszűnt!');
      else if (typeof item !== 'undefined' && item !== null && typeof item.vastagsag !== 'undefined' && item.vastagsag.length > 0 && item.vastagsag[0].description !== '18')
        setProductError(item.vastagsag[0].description + 'mm vastag');
      else
        setProductError(undefined);
    }
    if(field_code === 'SZIN'){
      if (typeof item !== 'undefined' && item !== null && item.status === 'HIANYCIKK')
        setSzinError('Figyelem az adott szín hiánycikk!');
      else if (typeof item !== 'undefined' && item !== null && item.status === 'KIFUTO')
        setSzinError('Figyelem az adott szín kifutó szín!');
      else if (typeof item !== 'undefined' && item !== null && item.status === 'MEGSZUNT')
        setSzinError('Figyelem a kiválasztott szín megszűnt!');
      else
        setSzinError(undefined);
    }
        
  }

  const deleteItem: () => void = () => {
    dispatch(productActions.removeProductById(productId));
  };

  const handleActive: () => void = () => {
    dispatch(productActions.toggleProductById(productId));
  };

  const handleKeypress: KeyboardEventHandler =
    (e) => {
      if (e.key === 'Enter') {
        onAddProduct(productId);
      }
      if (e.key > '9' || e.key < '0') {
        e.preventDefault();
      }
    };

  useEffect(() => {
    Object.keys(fieldOptions).forEach((fieldCode) => {
      const options = fieldOptions[fieldCode];
      const fieldType = fields.find(({ code }) => code === fieldCode)?.type;
      if (!fieldType || fieldType !== 'select')
        return;

      if (options?.length === 1) {
        if (fieldCode === 'PROD' || fieldCode === 'QUANT')
          return;

        startValidation("PROD", (valueMapper("PROD") as FieldOption));
        startValidation("SZIN", (valueMapper("SZIN") as FieldOption));

        const feature = product.features.find(({ code }) => code === fieldCode);
        if (!feature) {
          dispatch(productActions.modifyProductFeatureById(
            {
              id: productId,
              feature: {
                code: fieldCode,
                option_code: options[0].code,
                option_value: options[0].id,
              },
              fields,
            },
          ));
        }
      }
    });
  }, [product, fieldOptions, fields, productId]);


  const openRemoveDialog = () => {
    setRemoveDialog(true);
  };

  const handleCloseImage = () => setImageDialog(false);

  const onDeleteItem = () => {
    deleteItem();
    setRemoveDialog(false);
  };

  const handleClose = () => {
    setRemoveDialog(false);
  };

  const openAkril4DifferentEdgeModal = () => {
    setAkril4DifferentEdgeModal(true);
  };

  const closeFailAkril4DifferentEdgeModal = () => {
    if (!akril4DifferentEdgeEdit)
      dispatch(productActions.removeProductFeatureById({
        id: productId,
        featureCode: 'ELZ',
      }));
    setAkril4DifferentEdgeModal(false);
  };

  const openRalModal = () => {
    setRalModal(true);
  };

  const closeFailRalModal = () => {
    if (!ralEdit)
      dispatch(productActions.removeProductFeatureById({
        id: productId,
        featureCode: 'SZIN',
      }));
    setRalModal(false);
  };

  const closeSuccessRalModal = () => {
    setRalModal(false);
    setRalEdit(true);
  };

  const closeSuccessAkril4DifferentEdgeModal = () => {
    setAkril4DifferentEdgeModal(false);
    setAkril4DifferentEdgeEdit(true);
  };

  const handleSelectChange: (
    value: Value<FieldOption, false, false, false>,
    code: string,
    action?: AutocompleteChangeReason,
  ) => Promise<void> = async (item, code, action) => {
    if (item === null) return;
    if (catId === '-1') return;

    const field = fields.find(({ code: fieldCode }) => fieldCode === code);
    if (!field)
      return;

    //Quant check
    const minOrderVal = field.code === 'PROD' ? Math.floor(item.minorder ?? 1) : Math.floor((valueMapper('PROD') as FieldOption)?.minorder ?? 1);
    const orderMultiple = field.code === 'PROD' ? Math.floor(item.order_multiple ?? 1) : Math.floor((valueMapper('PROD') as FieldOption)?.order_multiple ?? 1);
    const quantity = field.code === 'QUANT' ? item.id : ((valueMapper('QUANT') as FieldOption)?.id ?? 1);

    if (minOrderVal > quantity)
      setQuantError(`Minimum ${minOrderVal} mennyiség rendelhető!`);
    else if ( (quantity - minOrderVal) % orderMultiple != 0 )
      setQuantError(`Választható mennyiségek: ${minOrderVal}, ${minOrderVal+orderMultiple}, ${minOrderVal+2*orderMultiple}, ${minOrderVal+3*orderMultiple}...`);
    else
      setQuantError(undefined);


    if(field.code === 'PROD'){
      startValidation(field.code, item);

      dispatch(productActions.modifyProductIdById(
        {
          key_id: productId,
          id: item.id,
        },
      ));
      return;

    }else if(field.code === 'QUANT'){

      dispatch(productActions.modifyProductQuantityById({
        id: productId,
        quantity: item.id === 0 ? '' : item.id,
      }));
      return;
      
    }else if(field.code === 'SZIN' || field.code === 'ELZ'){
      startValidation(field.code, item);

      if(field.code === 'SZIN'){
        if (item.code === 'RAL_NCS') {
          openRalModal();
        }
      }

      //Csak ELZ nem SZIN
      if(field.code === 'ELZ'){
        if(item.code === 'ELZ'){
          openAkril4DifferentEdgeModal();
        }
        ['ELZ1', 'ELZ2', 'ELZ3', 'ELZ4'].forEach(code =>
          dispatch(productActions.removeProductFeatureById({
            id: productId,
            featureCode: code,
          })),
        );
        setAkril4DifferentEdgeEdit(false);
      }
    }

    //Minden esetben kivéve (PROD ÉS QUANT)
    dispatch(productActions.modifyProductFeatureById(
      {
        id: productId,
        feature: {
          code: field.code,
          option_code: item.code,
          option_value: item.id,
        },
        fields,
      },
    ));

  };

  const handleCheckChange = () => {
    handleActive();
  };

  const handleOpenExtras = () => {
    setExtrasModal(true);
  };

  const onCloseExtras = () => {
    setExtrasModal(false);
  };

  const onShowProductImage: (image: string) => void = (image) => {
    setImage(image);
    setImageDialog(true);
  };

  const getHelperText: (field_code: string) => undefined|string = (field_code) => {
    if(field_code === 'SZIN' || field_code === 'ELZ')
      return szinError;
    else if(field_code === 'PROD')
      return productError;
    else if(field_code === 'QUANT')
      return quantError;

      return undefined;
  };

  const valueMapper: (fieldCode: string) => FieldOption | number | string | null | undefined
    = (fieldCode) => {

      const options = fieldOptions[fieldCode] ?? [];

      switch (fieldCode) {
        case 'PROD': {
          return options.find((option) => product.product_id === option.id) ?? null;
        }
        case 'ELZ': {
          return options.find((option) =>
            product.features.find(feature => feature.code === fieldCode)?.option_code === option.code ?? null,
          );
        }
        case 'QUANT': {
          return product.quantity ?? '';
        }
        case 'SZEL':
        case 'MAG':
          return product.features.find(feature => feature.code === fieldCode)?.keyed_value ?? '';
        default: {
          return options.find((option) =>
            product.features.find(feature => feature.code === fieldCode)?.option_code === option.code ?? null,
          );
        }
      }
    };

  const allRequiredFilled = product.validationState !== 'incomplete';

  const extraCount = useMemo(() => {
    if (!product)
      return 0;
    return product.extra_count ?? 0;
  }, [product]);

  const renderInput: (field: FieldType) => (params: AutocompleteRenderInputParams) => React.ReactNode = (field) => (params) => (
    <TextField
      {...params}
      margin="none"
      variant="standard"
      helperText={ getHelperText(field.code) }
      FormHelperTextProps={{ style: { color: 'red' }}}
      InputProps={{
        ...params.InputProps,
        endAdornment: (valueMapper(field.code) as FieldOption)?.imgUrl ?
          <>
            {params.InputProps.endAdornment}
            <div className={classes.itemInfo}>
              <InfoOutlinedIcon
                className={classes.icon}
                onClick={() => onShowProductImage(
                  (valueMapper(field.code) as FieldOption)?.imgUrl ?? '',
                )}
              />
            </div>
          </>
          : params.InputProps.endAdornment,
      }}
    />
  );

  return (
    <StyledTableRow className={`${halfValid || !allRequiredFilled ? '' : 'notValid'}`}>
      <StyledTableCell align="center" padding={'checkbox'}>
        <Checkbox
          checked={product.active}
          onChange={() => handleCheckChange()}
        />
      </StyledTableCell>
      {fields.filter(({ type }) => type === 'select').map((field, i) =>
        <StyledTableCell key={i} align={'left'}>
          <Grid container direction={'row'} wrap={'nowrap'} alignItems={'center'}>
            <FormControl fullWidth className={classes.formControl}>
              <Autocomplete
                noOptionsText={
                  enabledFields.includes(field.code) ?
                    'Nincs elérhető adat.' :
                    'Korábbi mező még nincs kitöltve'
                }
                options={enabledFields.includes(field.code) ?
                  fieldOptions[field.code] ?? [] : []}
                getOptionLabel={(option) => option.description ?? ''}
                //disabled={!enabledFields.includes(field.code)}
                getOptionDisabled={(option) => ((field.code === 'SZIN' || field.code === 'ELZ') && option.status === 'MEGSZUNT') || (field.code === 'PROD' && option.status === 'MEGSZUNT')}
                fullWidth
                autoHighlight
                autoComplete
                autoSelect
                openOnFocus
                disableClearable
                classes={{
                  inputRoot: classes.autocompleteInputRoot,
                }}
                value={valueMapper(field.code) as FieldOption ?? null}
                onChange={(e, newValue, reason) => handleSelectChange(newValue, field.code, reason)}
                renderInput={renderInput(field)}
              />
            </FormControl>
            {
              field.code === 'ELZ' && (valueMapper(field.code) as FieldOption)?.code === 'ELZ' ?
                <IconButton
                  onClick={openAkril4DifferentEdgeModal}
                >
                  <Edit />
                </IconButton> : <></>
            }
            {
              field.code === 'SZIN' && (valueMapper(field.code) as FieldOption)?.code === 'RAL_NCS' ?
                <IconButton
                  onClick={openRalModal}
                >
                  <Edit />
                </IconButton> : <></>
            }
          </Grid>
        </StyledTableCell>,
      )}
      <ProductSizeFields
        productId={productId}
      />
      {fields.filter(({ type, code }) => type === 'number' && code !== 'MAG' && code !== 'SZEL').map((field, i) =>
        <StyledTableCell key={i} align={'left'}>
          <TextField
            id={field.code}
            name={field.code}
            helperText={ getHelperText(field.code) }
            FormHelperTextProps={{ style: { color: 'red' }}}
            fullWidth
            inputMode="numeric"
            value={valueMapper(field.code)}
            inputProps={{
              step: 1,
              min: 0,
            }}
            type="text"
            onChange={(e) =>
              handleSelectChange({
                id: Number(e.target.value),
                code: field.code,
                description: '',
                status: '',
              }, field.code)}
            onKeyPress={handleKeypress}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </StyledTableCell>,
      )}
      {/* Akril, Alu és Front */}
      {catId !== 4 && catId !== 5 && catId !== 3 ? (
        <>
          <StyledTableCell>
            <Button
              onClick={() => handleOpenExtras()}
              variant={'contained'}
              color={'secondary'}
              disabled={!allRequiredFilled}
              fullWidth
              classes={{
                root: classes.buttonRootNoBottomMargin,
              }}
            >
              {extraCount > 0 ? `Extrák (${extraCount})` : 'Extrák'}
            </Button>
          </StyledTableCell>
        </>) : <></>}
        <StyledTableCell padding={'none'}>
        <Badge badgeContent={product?.note && product?.note !== '' ? <Check fontSize={'inherit'} /> : 0}
          color="primary">
          <Button
            onClick={() => setNoteDialog(true)}
            variant={'contained'}
            color={'secondary'}
            fullWidth
            disabled={!product?.product_id}
            classes={{
              root: classes.buttonRootNoBottomMargin,
            }}
          >
            Megjegyzés
          </Button>
        </Badge>
      </StyledTableCell>
      <StyledTableCell align={'center'}>
        <Tooltip title="Törlés" placement="left">
          <IconButton style={{ textAlign: 'right' }} onClick={openRemoveDialog} className={classes.icon}>
            <DeleteIcon color={'inherit'} />
          </IconButton>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell align={'center'}>
        <ProductValidator
          productId={productId}
        />
      </StyledTableCell>
      <ProductItemRemoveModal
        open={removeDialog}
        onClose={handleClose}
        onConfirm={onDeleteItem}
      />
      <ProductImageModal
        open={imageDialog}
        onClose={handleCloseImage}
        image={image}
      />
      <NoteModal
        open={noteDialog}
        productId={productId}
        onClose={() => setNoteDialog(false)}
      />
      <UniqueEdgesSelectorModal
        open={akril4DifferentEdgeModal}
        onClose={closeFailAkril4DifferentEdgeModal}
        onSuccess={closeSuccessAkril4DifferentEdgeModal}
        edges={fieldOptions['ELZ']}
        productId={productId}
      />
      <RalNumberInputModal
        open={ralModal}
        onClose={closeFailRalModal}
        onSuccess={closeSuccessRalModal}
        productId={productId}
      />
      <ExtrasPanel
        open={extrasModal}
        productId={productId}
        onClose={onCloseExtras}
      />
      <ProductSizeRuleManager product={product} />
    </StyledTableRow>
  );
});

