import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FieldOption = {
  code: string
  id: number
  description: string
  status: string
  imgUrl?: string
  minorder?: number
  order_multiple?: number
  vastagsag?: {
    code: string
    description: string
  }[]
  minta_tip?: {
    code: string
    description: string
  }[]
}

export type FieldOptionsState = {
  [name: string]: FieldOption[]
}

const initialState: FieldOptionsState = {}

type FieldOptionsReducer<T = void> = CaseReducer<FieldOptionsState, PayloadAction<T>>

type Reducers = {
  clear: FieldOptionsReducer
  add: FieldOptionsReducer<{name: string, options: FieldOption[]}>
}

export const fieldOptionsSlice = createSlice<FieldOptionsState, Reducers>(
  {
    name: 'fieldOptions',
    initialState,
    reducers: {
      clear: () => initialState,
      add: (state, {payload: {name, options}}) => {
        state[name] = options
      }
    }
  }
)

export const fieldOptionsActions = fieldOptionsSlice.actions
export const fieldOptionsReducer = fieldOptionsSlice.reducer
